<script>
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
import FlagButton from '@/components/FlagButton.vue';
import axios from 'axios'
import { userService } from "@/helpers/user.service"
import {errorCatcher} from "@/helpers/error-catcher";
import {logoHelper} from "@/helpers/logo-helper";
import InformationPanelRender from "@/components/account/information-panel-render.vue";
import Swal from "sweetalert2";
import {timeUtil} from "../../../helpers/time-util";

export default {
  components: {InformationPanelRender, FlagButton},
  data() {
    return {
      submitted: false,

      form: {
        code: ""
      }
    };
  },

  computed: {
    logoHelper() {
      return logoHelper
    },
    userService() {
      return userService
    },

    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    }
  },

  async created() {
    document.body.classList.add("auth-body-bg");
  },

  validations: {
    form: {
      code: {required, numeric, minLength: minLength(6), maxLength: maxLength(6)}
    }
  },

  methods: {

    tryToVerify() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.verify();
    },

    verify() {
      const json = JSON.stringify({
        "code": this.form.code
      })

      axios.post(`/user/session/authorize`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.submitted = false;
        this.code = "";

        this.$bvToast.toast(this.$t("verify.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        userService.login(true);
      }).catch((error) => {
        this.submitted = false;
        this.code = "";
        errorCatcher.catchErrors(error)
      })
    },

    resendCode() {
      const currentTime = new Date().getTime();
      if (this.$store.getters["lastclick/getP"] && this.$store.getters["lastclick/getP"] > currentTime) {
        Swal.fire(this.$t('message.error'), "Następny raz kod możesz wysłać za: " + timeUtil.toHumanReadableTime(this.$store.getters["lastclick/getP"] - currentTime), "error");
        return
      }

      axios.post(`/user/session/send/verification-code`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(async () => {
        this.$bvToast.toast(this.$t("verify.sent-code"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        const time = new Date(new Date().getTime() + (14 * 60 * 1000)).getTime()
        await this.$store.dispatch("lastclick/setP", time)

        this.submitted = false;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))" type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'" style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img :src="logoHelper.logo()" :height="getImageSize" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{  $t('landing-page-title') }}</h4>

                        <p class="text-muted">Weryfikacja nowej sesji</p>
                      </div>

                        <div class="p-2 mt-5">
                            <div class="form-horizontal">
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-mail-line auti-custom-input-icon"></i>
                                <label for="code">{{ $t('message.verification-code', { 'type': 'SMS' }) }}</label>
                                <input
                                    type="text"
                                    v-model="form.code"
                                    class="form-control"
                                    id="code"
                                    :placeholder="$t('message.type', { 'type': $t('message.verification-code', { 'type': 'SMS' }) })"
                                    :class="{ 'is-invalid': submitted && $v.form.code.$error }" />

                                <div v-if="submitted && $v.form.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.code.required">{{  $t('message.required') }}</span>
                                </div>

                                <div v-if="submitted && $v.form.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.code.numeric">{{  $t('message.is-number') }}</span>
                                </div>

                                <div v-if="submitted && $v.form.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.code.minLength">{{  $t('verify.error.length') }}</span>
                                </div>

                                <div v-if="submitted && $v.form.code.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.code.maxLength">{{  $t('verify.error.length') }}</span>
                                </div>
                              </div>

                              <div class="mt-4 text-center">
                                <div class="col-xl-12 row nopadding">
                                  <div class="col-xl-6 pl-0">
                                    <button @click="tryToVerify" class="btn btn-primary w-md waves-effect waves-light w-100">{{ $t('message.verify') }}</button>
                                  </div>
                                  <div class="col-xl-6 pr-0">
                                    <button @click="resendCode()" class="btn btn-outline w-md waves-effect waves-light w-100">{{ $t('verify.resend-code') }}</button>
                                  </div>
                                </div>
                              </div>

                              <div class="mt-4 text-center">
                                <a @click="userService.logout" class="clickable-element font-weight-medium text-primary">{{ $t('navbar.dropdown.logout')}}</a>
                              </div>
                            </div>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
            <information-panel-render/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>